// "confluence.fe.interaction.XXX"

export const MORE_ACTIONS_DROPDOWN_INTERACTION = 'more-actions-dropdown';
export const MOVE_PAGE_DIALOG_INTERACTION = 'move-page-dialog';
export const CONTENT_ANALYTICS_DIALOG_INTERACTION = 'content-analytics-dialog';
export const CONTENT_ANALYTICS_DIALOG_MENTIONS_TAB_INTERACTION =
	'content-analytics-dialog-mentions-tab';
export const RESTRICTIONS_DIALOG_INTERACTION = 'restrictions-dialog';
export const QUICK_SUMMARY_DIALOG_INTERACTION = 'quick-summary-dialog';
export const OPEN_LOOM_RECORDER_SDK_INTERACTION = 'open-loom-recorder-sdk';
export const INITIALIZE_LOOM_RECORDER_SDK_INTERACTION = 'initialize-loom-recorder-sdk';
export const COMMENTS_SUMMARY_COMPONENT_INTERACTION = 'comments_summary_component';
export const CREATE_SPACE_DRAWER_INTERACTION = 'create-space-drawer';
export const VIEW_AI_PRESENTATION_INTERACTION = 'ai-presentation-modal';
export const AI_CONTENT_CATCHUP_INTERACTION = 'ai-content-catchup';
