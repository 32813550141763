import { createStore, createHook } from 'react-sweet-state';

const Store = createStore({
	initialState: {
		areKeyboardShortcutsEnabled: false,
	},
	actions: {
		setKeyboardShortcutsEnabled:
			(areKeyboardShortcutsEnabled) =>
			({ setState }) => {
				setState({
					areKeyboardShortcutsEnabled,
				});
			},
	},
	name: 'areKeyboardShortcutsEnabled',
});

const getKeyboardShortcutsState = (state: { areKeyboardShortcutsEnabled: boolean }) => {
	return state.areKeyboardShortcutsEnabled;
};

export const useKeyboardShortcutsState = createHook(Store, {
	selector: getKeyboardShortcutsState,
});
