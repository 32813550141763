import {
	type CoordinationOptions,
	startMessage,
	stopMessage,
} from '../../services/engage-targeting';
import { isMessagingEnabled } from '../../utils/messaging';

export interface CoordinationClientType {
	start(messageId: string, variationId?: string): Promise<boolean>;
	stop(messageId: string): Promise<boolean>;
}

export interface CoordinationClientConfig {
	cloudId: string;
	stargateUrl: string;
	/**
	 * Deprecated. Instead atl-context is used internally.
	 */
	isFedRAMPEnvironment?: boolean;
	orgId?: string;
	workspaceId?: string;
	coordinationOptions?: CoordinationOptions;
}

export default class CoordinationClient implements CoordinationClientType {
	private cloudId: string;
	private stargateUrl: string;
	private orgId?: string;
	private workspaceId?: string;
	private coordinationOptions?: CoordinationOptions;

	/**
	 * Legacy constructor for creating a CoordinationClient. Assumes
	 * that the client is being used in a non-FedRAMP environment.
	 *
	 * This constructor has been deprecated, please use the
	 * CoordinationClientConfig constructor instead.
	 *
	 * @param cloudId The CloudID of the site
	 * @param stargateUrl The URL to use for sending requests to Stargate
	 * @deprecated {@link https://hello.atlassian.net/browse/ENGHEALTH-3359 Internal documentation for deprecation (no external access)}
	 */
	constructor(cloudId: string, stargateUrl: string);

	/**
	 * Creates a new client for coordinating messages using Engagement
	 * Platform.
	 *
	 * The only required options for the configuration are `cloudId` and
	 * `stargateUrl`.
	 *
	 * @param configOrCloudId Configuration for how the CoordinationClient should be created
	 * @param stargateUrl The URL to use for sending requests to Stargate
	 */
	constructor(config: CoordinationClientConfig);
	constructor(configOrCloudId: CoordinationClientConfig | string, stargateUrl?: string) {
		let config: CoordinationClientConfig;
		if (typeof configOrCloudId === 'string') {
			config = {
				cloudId: configOrCloudId,
				stargateUrl: stargateUrl as string,
				isFedRAMPEnvironment: false,
			};
		} else {
			config = configOrCloudId;
		}

		if (!config.cloudId || !config.stargateUrl) {
			throw new Error('Need a valid CloudId and Stargate url');
		}

		this.cloudId = config.cloudId;
		this.stargateUrl = config.stargateUrl;
		this.orgId = config.orgId;
		this.workspaceId = config.workspaceId;
		this.coordinationOptions = config.coordinationOptions;
	}

	async start(messageId: string, variationId?: string): Promise<boolean> {
		if (!isMessagingEnabled()) {
			return false;
		}

		return startMessage(
			{
				cloudId: this.cloudId,
				stargateUrl: this.stargateUrl,
				messageId,
				variationId,
				orgId: this.orgId,
				workspaceId: this.workspaceId,
			},
			this.coordinationOptions,
		);
	}

	async stop(messageId: string): Promise<boolean> {
		if (!isMessagingEnabled()) {
			return false;
		}

		return stopMessage(
			{
				cloudId: this.cloudId,
				stargateUrl: this.stargateUrl,
				messageId,
				orgId: this.orgId,
				workspaceId: this.workspaceId,
			},
			this.coordinationOptions,
		);
	}
}
