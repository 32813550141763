import { useMemo } from 'react';
import { ENABLE_CHOREOGRAPHER_GATE, type CoordinationClientType } from '@atlassiansox/engagekit-ts';

import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { getWorkspaceId } from '@confluence/analytics-web-client';
import { useAnalyticsEnvironment } from '@confluence/choreographer-services';

import { getCoordinationClient } from './getCoordinationClient';

export const useCoordinationClient = (): CoordinationClientType => {
	const { cloudId, activationId, environment } = useSessionData();

	const workspaceId = getWorkspaceId(cloudId, activationId);

	const disableEngagementForTests =
		sessionStorage.getItem('confluence.disable-engagement-for-tests') === 'true';

	const enableChoreographer = useBooleanFeatureFlag(ENABLE_CHOREOGRAPHER_GATE.confluence);
	const envType = useAnalyticsEnvironment(environment);

	return useMemo(
		() =>
			getCoordinationClient(
				disableEngagementForTests,
				cloudId,
				workspaceId,
				enableChoreographer,
				envType,
			),
		[disableEngagementForTests, cloudId, workspaceId, enableChoreographer, envType],
	);
};
